<template>

    <div v-if="talkingPoints" class="flex flex-col">
        <div class="mt-6 flex justify-between items-center">
            <div class="flex items-center">
                <div class="text-font-gray">Filter by:</div>
                <div class="header-btn-gray ml-3 relative" v-for="(doc, index) in typeDocs"
                     :key="index" :class="{ [doc.class]: selectedExtensions === doc.value }"
                     @click="filterByExtensions(doc.value)" >
                    <i :class="'text-xl mdi mdi-' + doc.name"></i>
                </div>
            </div>

            <div class="relative w-84">
                <div class="relative flex-none w-84">
                    <input type="text" placeholder="Search" v-model="searchInput" class="w-full h-8
                    rounded bg-box-40 flex flex-row justify-start items-center px-2" />
                </div>
            </div>
        </div>

        <div class="mt-8 relative h-full overflow-y-auto overflow-x-hidden">
            <div v-if="search(filteredTalkingPoints).length > 0" class="flex flex-wrap -mx-10 -my-2">
                <div v-for="(point, index) in search(filteredTalkingPoints)" :key="index"
                     class="px-10 py-2 w-1/2 overflow-y-auto">
                    <loader :loading="loading" />
                    <div class="box flex items-center p-4">
                        <div class="flex-none rounded h-16 w-16 flex items-center justify-center text-center cursor-pointer"
                             :class="getClassByExtension(point.file)" @click="openFile(point.file, 'file')">
                            <i class="mdi text-4xl" :class="getIconByExtension(point.file)"></i>
                        </div>
                        <div class="flex-1 min-w-0">
                            <p class="text-lg text-font-light font-medium px-4 truncate-2 leading-normal">{{ point.title }}</p>
                        </div>
                        <div class="text-sm text-font-gray self-start">
                            <p>{{ point.date | moment('MM/DD/YYYY') }}</p>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else class="w-full h-full flex justify-center items-center">
                <span class="module-title text-font-gray">There are no files of the selected extension</span>
            </div>
        </div>
        <div v-if="pdfSelected" class="absolute w-3/5 h-192 z-50 flex flex-col bg-body rounded-md">
            <div class="h-10 flex flex-row justify-between items-center px-2">
                <span class="text-md text-white font-semibold">{{ fileOpen }}</span>
                <i class="mdi mdi-close text-white font-semibold cursor-pointer" @click="openFile()"></i>
            </div>
            <div class="flex-1">
                <iframe id="iframepdf" :src="pdfSelected" class="h-full w-full"></iframe>
            </div>
        </div>
    </div>
    <div v-else class="h-full w-full flex flex-col justify-center items-center">
        <loader :loading="loading" />
    </div>
</template>

<script>
    import { state } from '@/store';

    export default {
        data() {
            return {
                talkingPoints: null,
                loading: false,
                typeDocs: this.$typeDocs,
                pdfSelected: false,
                fileOpen: false,
                searchInput: '',
                selectedExtensions: []
            }
        },
        methods: {
            load() {
                this.loading = true
                this.axios.get(process.env.VUE_APP_BACKEY_CMS_URL + 'list?resource=TalkingPointAndFirmDoc&sorts[date]=DESC&filters[active]=1', {params: state.globalParams})
                    .then(response => {
                        this.talkingPoints = response.data
                        this.loading = false
                    })
            },
            filterByExtensions(extensions) {

                if (extensions === this.selectedExtensions) this.selectedExtensions = []
                else this.selectedExtensions = extensions
            },
            getIconByExtension(file) {
                let result = ''
                result += this.$iconByExtension(file)
                return result
            },
            getClassByExtension(file) {
                let result = ''
                result += this.$colorByExtension(file)
                return result
            },
            openFile(file, type) { this.$openFile(file, type) },
            search(data) { return this.$search(data, this.searchInput) }
        },
        computed: {
            filteredTalkingPoints() {
                if (this.selectedExtensions.length === 0) { return this.talkingPoints }
                return this.talkingPoints.filter(({ file }) => {
                    const pathProps = file.split('.');
                    const extension = pathProps[pathProps.length - 1].toLowerCase();
                    return this.selectedExtensions.includes(extension);
                });
            },
            params() {
                let params = state.globalParams
                if (this.$route.params.catId) {
                    params = {...params, ...{ sectorAmbiciona: this.$route.params.catId }}
                }
                return params
            }
        },
        watch: {
            params() { this.load() }
        },
        mounted() { this.load() }
    };
</script>